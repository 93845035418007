import { Drawer as MuiDrawer } from '@mui/material'
import useFocusTrap from 'app/hooks/useFocusTrap'
import useKeyPress from 'app/hooks/useKeyPress'
import React from 'react'

interface DrawerProps {
  anchor?: 'left' | 'top' | 'right' | 'bottom'
  open: boolean
  onClose?: () => void
  children: React.ReactNode
  className?: string
  variant?: 'permanent' | 'persistent' | 'temporary'
}

export default function Drawer({
  anchor = 'right',
  children,
  onClose,
  open,
  className,
  variant = 'temporary',
}: DrawerProps) {
  const drawerRef = React.useRef<HTMLDivElement>(null)

  useKeyPress('Escape', onClose ?? ((() => {}) as any))
  useFocusTrap(drawerRef)

  if (!open && variant === 'temporary') {
    return null
  }

  return (
    <MuiDrawer
      anchor={anchor}
      className={className}
      open={open}
      onClose={onClose || ((() => {}) as any)}
      variant={variant}
      role="dialog"
      PaperProps={{
        sx: {
          maxWidth: '100vw',
          width: 'auto',
        },
      }}>
      {children}
    </MuiDrawer>
  )
}
